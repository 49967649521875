
import router from "@/router"
import Dialog from "@/components/Dialog.vue"
import {onMounted} from "vue"
import {mapActions} from "vuex"
import { useStore } from 'vuex'

export default {
	name: "Logout",
	components: { Dialog },
	setup() {
		const store = useStore()

		onMounted(() => {
			setTimeout(() => {
				store.dispatch("session/setAllUserList")
				store.dispatch("session/clearLocalStorage")
				store.dispatch("dialog/closeDialog")
				store.dispatch("session/setIsValid", 0)
				store.dispatch("session/clearSchema")
				store.dispatch("form_data/clearFormRecords")
				store.dispatch("profile/clearProfile")
				router.push("/login")

			}, 1500)
		})

		return {}
	},
	methods: {
		...mapActions("dialog", ["openDialog", "closeDialog",
			"setIcon", "setIconType", "setMessage",
			"setButtonLeftText", "setButtonLeftFunc",
			"setButtonRightText", "setButtonRightFunc",
			"setButtonXText", "setButtonXFunc"])
	},
	mounted() {
		this.setMessage("Logging out...")
		this.setIconType("has-text-primary")
		this.setIcon("fa-sign-out")
		this.setButtonLeftFunc(null)
		this.setButtonRightFunc(null)
		this.setButtonXText("X")
		this.setButtonXFunc(null)
		this.openDialog()
	}
}
